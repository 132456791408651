import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Image from 'next/image'
import React from 'react'
import { getStrapiImage } from '../../lib/api'

const Speakers: React.FC<Props<Speakers>> = ({ data }) => {
  return (
    <Stack sx={{ bgcolor: 'accent.main', my: 6, py: 6 }}>
      <Container>
        <Box
          sx={{
            bgcolor: 'background.paper',
            px: { xs: 2, md: 8 },
            py: 4,
            borderRadius: 6,
          }}
        >
          <Stack alignItems="center">
            <Typography
              variant="h1"
              sx={{ width: 1 }}
              textAlign="center"
              noWrap
              fontWeight={800}
              color="accent.main"
            >
              {data?.sub}
            </Typography>
            <Typography variant="h4" fontWeight={700} sx={{ mt: '-70px' }}>
              {data?.titre}
            </Typography>
          </Stack>
          <Grid container sx={{ py: {xs: 4, md: 8} }} justifyContent="center" spacing={4}>
            {data?.liste?.map((el, i) => (
              <Grid xs={12} key={i} item md={3}>
                <Stack spacing={1}>
                  <Stack
                    sx={{
                      width: 1,
                      height: 250,
                      position: 'relative',
                      borderRadius: 2,
                      overflow: 'hidden',
                    }}
                  >
                    <Image
                      alt="Updev Challenge"
                      src={getStrapiImage(el.media)}
                      layout="fill"
                      objectFit="cover"
                      objectPosition="center"
                      // placeholder="blur"
                      // blurDataURL={getStrapiImagePlaceholder(el.media)}
                      priority
                    />
                  </Stack>
                  <Typography variant="h6" textAlign="center">
                    {el?.nom}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    textAlign="center"
                    variant="caption"
                  >
                    {el?.description}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Stack>
  )
}

export default Speakers
